<template>
  <div id="container" class="pace-top app app-full-height app-without-header">
    <!-- BEGIN coming-soon -->
    <div class="coming-soon">
      <div class="flex-1">
        <div class="coming-soon-timer">
          <div id="timer"></div>
        </div>
        <!-- BEGIN coming-soon-content -->
        <div class="coming-soon-content d-flex flex-column">
          <div class="flex-1 mb-3">
            <h2 class="mb-3">We're coming soon!</h2>
            <p class="mb-4">We are working very hard on the new version of our site.<br /> It will bring a lot of new features. Stay tuned!</p>
            <template v-if="showSubscribeDivs">
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Email Address" />
                <button type="submit" class="btn btn-outline-theme">SUBSCRIBE</button>
              </div>
              <div class="mb-1 small text-white text-opacity-50">* Subscribe and get notified for latest news</div>
            </template>
          </div>
          <div class="text-center small text-white text-opacity-50">
            <!-- &copy; 2022 SeanTheme Right Reserved -->
            Copyright &copy; 2022 CIMON Co., Ltd. All rights reserved.
          </div>
        </div>
        <!-- END coming-soon-content -->
      </div>
    </div>
    <!-- END coming-soon -->
    
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>
    <!-- END btn-scroll-top -->
    
    <ThemePanel v-show="showThemePanel" ref="themePanel"></ThemePanel>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ThemePanel from "@/components/ThemePanel.vue"

export default {
  components: {
    ThemePanel
  },

  name: 'ComingSoonView',
  props: {
    date: {
      type: Object,
      default: new Date("2022-09-18T09:00:00")
    },
    showSubscribeDivs: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showThemePanel: false,
    }
  },

  methods: {
    async init() {
      let elements = document.getElementsByName(this.$options.name);
      if (elements.length > 0)
        return;

      let scripts = [
        "assets/plugins/kbw-countdown/dist/js/jquery.plugin.js",
        "assets/plugins/kbw-countdown/dist/js/jquery.countdown.js",
        // "assets/js/demo/page-coming-soon.demo.js",
      ];

      for (let i = 0; i < scripts.length; i++) {
        let loaded = new Promise((resolve) => {
          let externalScript = document.createElement('script');
          externalScript.onload = () => resolve();
          externalScript.setAttribute('src', scripts[i]);
          // externalScript.setAttribute("id", scripts[i]);
          externalScript.setAttribute("name", this.$options.name);
          document.head.appendChild(externalScript);
        });
        await loaded;
      }
    },

    handleRenderCountdownTimer() {
      let date = this.date;
      if (!date) {
        let newYear = new Date();
        newYear = new Date(newYear.getFullYear() + 1, 1 - 1, 1);
        date = newYear;
      }
      $('#timer').countdown({until: date});
    },
  },

  mounted() {
    this.init().then(() => {
      this.handleRenderCountdownTimer();
    });
    // await this.nextTick();
    // setTimeout(() => {
    //   // console.log("Delayed for 1 second.")
    //   this.handleRenderCountdownTimer();
    // }, 1000);
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/public/assets/plugins/kbw-countdown/dist/css/jquery.countdown.css";
</style>
