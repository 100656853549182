<template>
  <div id="container" class="pace-top app app-full-height app-without-header">
    <!-- BEGIN login -->
    <div class="login">
      <!-- BEGIN login-content -->
      <div class="login-content">
        <!-- <form action="index.html" method="POST" name="login_form"> -->
          <h1 class="text-center">Sign In</h1>
          <div class="text-white text-opacity-50 text-center mb-4">
            For your protection, please verify your identity.
          </div>
          <div class="mb-3">
            <label class="form-label">Email Address <span class="text-danger">*</span></label>
            <input ref="email" type="text" class="form-control form-control-lg bg-white bg-opacity-5" value="" placeholder="" />
          </div>
          <div class="mb-3">
            <div class="d-flex">
              <label class="form-label">Password <span class="text-danger">*</span></label>
              <a href="#" class="ms-auto text-white text-decoration-none text-opacity-50">Forgot password?</a>
            </div>
            <input ref="password" type="password" class="form-control form-control-lg bg-white bg-opacity-5" value="" placeholder="" />
          </div>
          <div class="mb-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="customCheck1" />
              <label class="form-check-label" for="customCheck1">Remember me</label>
            </div>
          </div>
          <!-- <button type="submit" class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Sign In</button> -->
          <button class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" @click="signIn">Sign In</button>
          <div class="text-center text-white text-opacity-50">
            Don't have an account yet? <a href="register">Sign up</a>.
          </div>
        <!-- </form> -->
      </div>
      <!-- END login-content -->
    </div>
    <!-- END login -->
    
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>
    <!-- END btn-scroll-top -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Amplify, { Auth, Storage } from 'aws-amplify';





// function test() {
//   console.log("hello, world");
// }

async function uploadFile() {
  // Public level
  let result = await Storage.put("test.txt", "Hello");

  // Protected level
  result = await Storage.put("test.txt", "Protected Content", {
    level: "protected",
    contentType: "text/plain",
  });

  // Private level
  result = await Storage.put("test.txt", "Private Content", {
    level: "private",
    contentType: "text/plain",
  });

  Storage.put("test.txt", "File content", {
    progressCallback(progress) {
      console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
    },
  });

}

function processStorageList(result) {
  let files = []
  let folders = new Set()
  result.forEach(res => {
    if (res.size) {
      files.push(res)
      // sometimes files declare a folder with a / within then
      let possibleFolder = res.key.split('/').slice(0,-1).join('/')
      if (possibleFolder) folders.add(possibleFolder)
    } else {
      folders.add(res.key)
    }
  })
  return {files, folders}
}

function listPrivateFiles() {
  const list = Storage.list('', { level: 'private' }) // for listing ALL files without prefix, pass '' instead
    // .then(result => console.log(result))
    .then(result => processStorageList(result))
    .catch(err => console.log(err))
}

import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
     aws_pubsub_region: 'us-west-2',
     aws_pubsub_endpoint: 'wss://a1mv915ztqjeum-ats.iot.us-west-2.amazonaws.com/mqtt',
   }));

async function processData(data) {
  console.log('Message received', data);

  if (data["value"]["message"] == "hello, world") {
    await PubSub.publish('myTopic1', { msg: 'Hello to all subscribers!' });
  }
}

function testPubSub() {
  PubSub.subscribe('myTopic').subscribe({
      next: data => processData(data),
      error: error => console.error(error),
      complete: () => console.log('Done'),
  });


}

// Sign in
async function signIn() {
  try {
      const user = await Auth.signIn("sean.li@cimoninc.com", "Aa112211@");
      // console.log(user);
      Auth.currentCredentials().then((info) => {
        const cognitoIdentityId = info.identityId;
        console.log(cognitoIdentityId)
      });

      // uploadFile();
      listPrivateFiles();
      // console.log(files);

      testPubSub();

  } catch (error) {
      console.log('error signing in', error);
  }
}

// const SubmitButton = document.getElementById("SubmitButton");

// SubmitButton.addEventListener("click", (evt) => {
//   // test();
//   // console.log("hello, world");
//   signIn();
// });

// Re-send confirmation code
async function resendConfirmationCode() {
  try {
    await Auth.resendSignUp(username);
    console.log('code resent successfully');
  } catch (err) {
    console.log('error resending code: ', err);
  }
}

// Sign out
async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export default {
  name: 'LoginView',
  // components: {
  //   HelloWorld
  // }

  data() {
    return {
      message: "hello, world"
    }
  },

  methods: {
    // Sign in
    async signIn() {
      try {
        let email = this.$refs.email.value;
        let password = this.$refs.password.value;
        // console.log("Username: " + email);
        // console.log("Password: " + password);
        const user = await Auth.signIn(email, password);
        // console.log(user);
        // Auth.currentCredentials().then((info) => {
        //   const cognitoIdentityId = info.identityId;
        //   console.log(cognitoIdentityId)
        // });

        // this.$router.push({ name: 'Dashboard', params: {user: user} });
        // this.$router.push('Dashboard');
        window.location.href = 'dashboard';
        // uploadFile();
        // listPrivateFiles();
        // console.log(files);

        // testPubSub();

      } catch (error) {
        console.log('error signing in', error);
      }
    }
  }
}

</script>
