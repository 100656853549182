<template>
  <!-- BEGIN login -->
  <div class="login">
    <!-- BEGIN login-content -->
    <div class="login-content">
      <!-- <form action="index.html" method="POST" name="login_form"> -->
        <h1 class="text-center">Sign In</h1>
        <div class="text-white text-opacity-50 text-center mb-4">
          For your protection, please verify your identity.
        </div>
        <div class="mb-3">
          <label class="form-label">Email Address <span class="text-danger">*</span></label>
          <input ref="email" v-model="username" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
          <div ref="emailFeedback" class="invalid-feedback">{{emailFeedbackMessage}}</div>
        </div>
        <div class="mb-3">
          <div class="d-flex">
            <label class="form-label">Password <span class="text-danger">*</span></label>
            <a href="#" class="ms-auto text-white text-decoration-none text-opacity-50" @click="this.$parent.openPage('PasswordRecoveryPage', this.username);">Forgot password?</a>
          </div>
          <input ref="password" type="password" class="form-control form-control-lg bg-white bg-opacity-5" value="" placeholder="" />
          <div ref="passwordFeedback" class="invalid-feedback">{{passwordFeedbackMessage}}</div>
        </div>
        <div class="mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="customCheck1" />
            <label class="form-check-label" for="customCheck1">Remember me</label>
          </div>
        </div>
        <!-- <button type="submit" class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Sign In</button> -->
        <button class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" @click="signIn">Sign In</button>
        <div class="text-center text-white text-opacity-50">
          Don't have an account yet? <a href="#" @click="this.$parent.openPage('RegisterPage');">Sign up</a>.
        </div>
      <!-- </form> -->
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login -->
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Auth } from 'aws-amplify';

export default {
  name: 'LoginPage',
  // components: {
  //   HelloWorld
  // }
  props: {
    username: String,
  },

  data() {
    return {
      message: "hello, world",
      emailFeedbackMessage: "",
      passwordFeedbackMessage: "",
    }
  },

  methods: {
    validateInputs(email, password) {
      // Reset
      this.$refs.email.classList.remove("is-invalid");
      this.$refs.password.classList.remove("is-invalid");

      let result = true;
      if (!this.$parent.validateEmail(email)) {
        console.log("Invalid email");
        this.emailFeedbackMessage = "Please enter a valid email.";
        this.$refs.email.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(password)) {
        console.log("Password is empty.");
        this.passwordFeedbackMessage = "Please enter a password.";
        this.$refs.password.classList.add("is-invalid");
        result = false;
      }

      if (!result)
        this.clearPassword();
      return result;
    },

    // Sign in
    async signIn() {
      let email = this.$refs.email.value;
      let password = this.$refs.password.value;
      // console.log("Username: " + email);
      // console.log("Password: " + password);
      // Validate inputs
      if (!this.validateInputs(email, password))
        return;

      try {
        const user = await Auth.signIn(email, password);

        // this.$router.push('Dashboard');
        window.location.href = 'dashboard';
      } catch (error) {
        console.log('error signing in', error);
        this.handleError(error);
      }
    },

    handleError(error) {
      this.clearPassword();
      switch (error.name) {
        case "UserNotFoundException":
          console.log(error.message);
          this.emailFeedbackMessage = error.message;
          this.$refs.email.classList.add("is-invalid");
          break;
        case "NotAuthorizedException":
          console.log(error.message);
          this.emailFeedbackMessage = error.message;
          this.$refs.email.classList.add("is-invalid");
          this.passwordFeedbackMessage = error.message;
          this.$refs.password.classList.add("is-invalid");
          break;
        case "UserNotConfirmedException":
          console.log(error.message);
          this.$parent.openPage("RegisterConfirmationPage", this.username);
          break;
      }
    },

    clearPassword() {
      this.$refs.password.value = "";
    },
  }
}

</script>
