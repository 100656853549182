<template>
  <div id="container" class="pace-top app app-full-height app-without-header">
    <!-- BEGIN error -->
    <div class="error-page">
      <!-- BEGIN error-page-content -->
      <div class="error-page-content">
        <div class="card mb-5 mx-auto" style="max-width: 320px;">
          <div class="card-body">
            <div class="card">
              <div class="error-code">404</div>
              <div class="card-arrow">
                <div class="card-arrow-top-left"></div>
                <div class="card-arrow-top-right"></div>
                <div class="card-arrow-bottom-left"></div>
                <div class="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>
        <h1>Oops!</h1> 
        <h3>We can't seem to find the page you're looking for</h3>
        <hr />
        <p class="mb-1">
          Here are some helpful links instead:
        </p>
        <p class="mb-5">
          <!-- <a href="index.html" class="text-decoration-none text-white text-opacity-50">Home</a>
          <span class="link-divider"></span>
          <a href="page_search_results.html" class="text-decoration-none text-white text-opacity-50">Search</a>
          <span class="link-divider"></span>
          <a href="email_inbox.html" class="text-decoration-none text-white text-opacity-50">Email</a>
          <span class="link-divider"></span>
          <a href="calendar.html" class="text-decoration-none text-white text-opacity-50">Calendar</a>
          <span class="link-divider"></span>
          <a href="settings.html" class="text-decoration-none text-white text-opacity-50">Settings</a>
          <span class="link-divider"></span>
          <a href="helper.html" class="text-decoration-none text-white text-opacity-50">Helper</a> -->
          <a href="/dashboard" class="text-decoration-none text-white text-opacity-50">Dashboard</a>
          <span class="link-divider"></span>
          <a href="/render?name=test" class="text-decoration-none text-white text-opacity-50">Render</a>
        </p>
        <a href="javascript:window.history.back();" class="btn btn-outline-theme px-3 rounded-pill"><i class="fa fa-arrow-left me-1 ms-n1"></i> Go Back</a>
      </div>
      <!-- END error-page-content -->
    </div>
    <!-- END error -->
    
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>
    <!-- END btn-scroll-top -->
    
    <ThemePanel v-show="showThemePanel" ref="themePanel"></ThemePanel>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ThemePanel from "@/components/ThemePanel.vue"

export default {
  components: {
    ThemePanel
  },
  
  name: 'ErrorView',

  data() {
    return {
      showThemePanel: false,
    }
  },

  methods: {
  },

  mounted() {
  }
}

</script>
