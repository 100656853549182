<template>
  <div id="container" class="pace-top app app-full-height app-without-header">
    <!-- BEGIN login -->
    <component :is="currentPage" :username="username"></component>
    <!-- END login -->
    
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>
    <!-- END btn-scroll-top -->

    <ThemePanel v-show="showThemePanel" ref="themePanel"></ThemePanel>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LoginPage from '../components/Auth/Static/LoginPage.vue';
import RegisterPage from '../components/Auth/Static/RegisterPage.vue';
import RegisterConfirmationPage from '../components/Auth/Static/RegisterConfirmationPage.vue';
import PasswordRecoveryPage from '../components/Auth/Static/PasswordRecoveryPage.vue';
import ThemePanel from '../components/ThemePanel.vue'
import { Auth } from 'aws-amplify';

export default {
  name: 'AuthView',
  components: {
    LoginPage,
    RegisterPage,
    RegisterConfirmationPage,
    PasswordRecoveryPage,
    ThemePanel
  },

  data() {
    return {
      message: "hello, world",
      currentPage: "LoginPage",
      username: "",
      emailRegEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showThemePanel: false,
    }
  },

  methods: {
    openPage(page, username) {
      this.username = username;
      this.currentPage = page;
    },

    isEmpty(text) {
      return (text == "");
    },
    validateEmail(email) {
      if (this.isEmpty(email))
        return false;
      return this.emailRegEx.test(email);
    }
  },

  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'F4') {
        // console.log("F4 pressed");
        this.showThemePanel = !this.showThemePanel;
      }
    });
  },
}

</script>
