<template>
  <!-- BEGIN register -->
  <div class="register">
    <!-- BEGIN register-content -->
    <div class="register-content">
      <!-- <form action="index.html" method="POST" name="register_form"> -->
      <h1 class="text-center">Sign Up</h1>
      <p class="text-white text-opacity-50 text-center">One Admin ID is all you need to access all the Admin services.</p>
      <div class="mb-3">
        <label class="form-label">Name <span class="text-danger">*</span></label>
        <input ref="name" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="e.g John Smith" value="" />
        <div ref="nameFeedback" class="invalid-feedback">{{nameFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Email Address <span class="text-danger">*</span></label>
        <input ref="email" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="username@address.com" value="" />
        <div ref="emailFeedback" class="invalid-feedback">{{emailFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Password <span class="text-danger">*</span></label>
        <input ref="password" @input="verifyConfirmPassword" type="password" class="form-control form-control-lg bg-white bg-opacity-5" value="" />
        <div ref="passwordFeedback" class="invalid-feedback">{{passwordFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
        <input ref="confirmPassword" @input="verifyConfirmPassword" type="password" class="form-control form-control-lg bg-white bg-opacity-5" value="" />
        <div ref="confirmPasswordFeedback" class="invalid-feedback">{{confirmPasswordFeedbackMessage}}</div>
      </div>

      <div class="mb-3">
        <label class="form-label">Phone Number <span class="text-danger">*</span></label>
        <input ref="phoneNumber" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="+1-702-820-1060" value="" />
        <div ref="phoneNumberFeedback" class="invalid-feedback">{{phoneNumberFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Gender <span class="text-danger">*</span></label>
        <select class="form-select form-select-lg bg-white bg-opacity-5">
          <option>Female</option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-label">Company Name <span class="text-danger">*</span></label>
        <input ref="companyName" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="CIMON Inc" value="" />
        <div ref="companyNameFeedback" class="invalid-feedback">{{companyNameFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Business Type <span class="text-danger">*</span></label>
        <select class="form-select form-select-lg bg-white bg-opacity-5">
          <option>Distributor</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Industry <span class="text-danger">*</span></label>
        <select class="form-select form-select-lg bg-white bg-opacity-5">
          <option>Industrial Authomation</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Country <span class="text-danger">*</span></label>
        <select class="form-select form-select-lg bg-white bg-opacity-5">
          <option>United States</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">City <span class="text-danger">*</span></label>
        <input ref="city" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="Henderson" value="" />
        <div ref="cityFeedback" class="invalid-feedback">{{cityFeedbackMessage}}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Zip Code <span class="text-danger">*</span></label>
        <input ref="zipCode" type="text" class="form-control form-control-lg bg-white bg-opacity-5" placeholder="89052" value="" />
        <div ref="zipCodeFeedback" class="invalid-feedback">{{zipCodeFeedbackMessage}}</div>
      </div>
      
      <!-- <div class="mb-3">
        <label class="form-label">Date of Birth <span class="text-danger">*</span></label>
        <div class="row gx-2">
          <div class="col-6">
            <select class="form-select form-select-lg bg-white bg-opacity-5">
              <option>Month</option>
            </select>
          </div>
          <div class="col-3">
            <select class="form-select form-select-lg bg-white bg-opacity-5">
              <option>Day</option>
            </select>
          </div>
          <div class="col-3">
            <select class="form-select form-select-lg bg-white bg-opacity-5">
              <option>Year</option>
            </select>
          </div>
        </div>
      </div> -->
      <div class="mb-3">
        <div class="form-check">
          <input ref="termsAndConditions" @input="verifyTermsAndConditions" class="form-check-input" type="checkbox" value="" id="customCheck1" />
          <label class="form-check-label" for="customCheck1">I have read and agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</label>
          <div ref="termsAndConditionsFeedback" class="invalid-feedback">{{termsAndConditionsFeedbackMessage}}</div>
        </div>
      </div>
      <div class="mb-3">
        <!-- <button type="submit" class="btn btn-outline-theme btn-lg d-block w-100">Sign Up</button> -->
        <button class="btn btn-outline-theme btn-lg d-block w-100" @click="signUp">Sign Up</button>
      </div>
      <div class="text-white text-opacity-50 text-center">
        Already have an Admin ID? <a href="#" @click="this.$parent.openPage('LoginPage');">Sign In</a>
      </div>
      <!-- </form> -->
    </div>
    <!-- END register-content -->
  </div>
  <!-- END register -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Auth } from 'aws-amplify';


export default {
  name: 'RegisterPage',
  // components: {
  //   HelloWorld
  // }
  props: {
    username: String,
  },

  data() {
    return {
      nameFeedbackMessage: "",
      emailFeedbackMessage: "",
      passwordFeedbackMessage: "",
      confirmPasswordFeedbackMessage: "",
      phoneNumberFeedbackMessage: "",
      companyNameFeedbackMessage: "",
      cityFeedbackMessage: "",
      zipCodeFeedbackMessage: "",
      termsAndConditionsFeedbackMessage: "",
    }
  },

  methods: {
    verifyConfirmPassword() {
      let password = this.$refs.password.value;
      let confirmPassword = this.$refs.confirmPassword.value;
      if (!password && !confirmPassword)
        return;

      if (password != confirmPassword) {
        this.confirmPasswordFeedbackMessage = "Please enter the same password.";
        this.$refs.confirmPassword.classList.add("is-invalid");
      } else {
        this.$refs.confirmPassword.classList.remove("is-invalid");
        this.$refs.confirmPassword.classList.add("is-valid");
      }
    },
    verifyTermsAndConditions() {
      let termsAndConditions = this.$refs.termsAndConditions.checked;

      if (termsAndConditions) {
        this.$refs.termsAndConditions.classList.remove("is-invalid");
      }
    },

    validatePhoneNumber(phoneNumber) {
      if (this.$parent.isEmpty(phoneNumber))
        return false
      return true;
    },

    validateInputs(email, password) {
      // Reset
      this.$refs.email.classList.remove("is-invalid");
      this.$refs.password.classList.remove("is-invalid");
      this.$refs.confirmPassword.classList.remove("is-invalid");
      this.$refs.name.classList.remove("is-invalid");
      this.$refs.phoneNumber.classList.remove("is-invalid");
      this.$refs.companyName.classList.remove("is-invalid");
      this.$refs.city.classList.remove("is-invalid");
      this.$refs.zipCode.classList.remove("is-invalid");
      this.$refs.termsAndConditions.classList.remove("is-invalid");

      let confirmPassword = this.$refs.confirmPassword.value;
      let name = this.$refs.name.value;
      let phoneNumber = this.$refs.phoneNumber.value;
      let companyName = this.$refs.companyName.value;
      let city = this.$refs.city.value;
      let zipCode = this.$refs.zipCode.value;
      let termsAndConditions = this.$refs.termsAndConditions.checked;

      let result = true;
      if (!this.$parent.validateEmail(email)) {
        console.log("Invalid email");
        this.emailFeedbackMessage = "Please enter a valid email.";
        this.$refs.email.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(password)) {
        console.log("Password is empty.");
        this.passwordFeedbackMessage = "Please enter a password.";
        this.$refs.password.classList.add("is-invalid");
        result = false;
      }
      if (confirmPassword != password) {
        console.log("Passwords do not match.");
        this.confirmPasswordFeedbackMessage = "Please enter the same password.";
        this.$refs.confirmPassword.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(name)) {
        console.log("Name is empty.");
        this.nameFeedbackMessage = "Please provide your name.";
        this.$refs.name.classList.add("is-invalid");
        result = false;
      }
      if (!this.validatePhoneNumber(phoneNumber)) {
        console.log("Invalid phone number");
        this.phoneNumberFeedbackMessage = "Please enter a valid phone number.";
        this.$refs.phoneNumber.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(companyName)) {
        console.log("Company name is empty.");
        this.companyNameFeedbackMessage = "Please provide your company name.";
        this.$refs.companyName.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(city)) {
        console.log("City is empty.");
        this.cityFeedbackMessage = "Please enter a city.";
        this.$refs.city.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(zipCode)) {
        console.log("Zip code is empty.");
        this.zipCodeFeedbackMessage = "Please enter a zip code.";
        this.$refs.zipCode.classList.add("is-invalid");
        result = false;
      }
      if (!termsAndConditions) {
        console.log("Terms and conditions are not checked.");
        this.termsAndConditionsFeedbackMessage = "You must agree before submitting.";
        this.$refs.termsAndConditions.classList.add("is-invalid");
        result = false;
      }

      if (!result)
        this.clearPassword();
      return result;
    },

    async signUp() {
      let username = this.$refs.email.value;
      let password = this.$refs.password.value;
      // let email = this.$refs.email.value;
      console.log(username);
      console.log(password);
      // Validate inputs
      if (!this.validateInputs(username, password))
        return;

      try {
        // console.log(username);
        // console.log(password);
        let email = username;
        // let phone_number = "+17028201060"
        const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
              email,          // optional
              // phone_number,   // optional - E.164 number convention
              // other custom attributes 
              //'custom:favorite_flavor': 'Cookie Dough'  // custom attribute, not standard
          }
        });
        console.log(user);

        // this.$router.push({ name: 'confirm', params: {username: username} });
        this.$parent.openPage("RegisterConfirmationPage", username);
      } catch (error) {
        console.log('error signing up:', error);
        this.handleError(error);
      }
    },

    handleError(error) {
      this.clearPassword();
      switch (error.name) {
        case "InvalidPasswordException":
          console.log(error.message);
          this.passwordFeedbackMessage = error.message;
          this.$refs.password.classList.add("is-invalid");
          break;
        case "UsernameExistsException":
          console.log(error.message);
          this.emailFeedbackMessage = error.message;
          this.$refs.email.classList.add("is-invalid");
          break;
      }
    },

    clearPassword() {
      this.$refs.password.value = "";
      this.$refs.confirmPassword.value = "";
    },
  }
}
</script>
