<template>
  <!-- <sidebar id="sidebar" class="app-sidebar"> -->
  <!-- BEGIN #sidebar -->
  <div id="sidebar" class="app-sidebar">

    <!-- BEGIN scrollbar -->
    <div class="app-sidebar-content" data-scrollbar="true" data-height="100%">
      <!-- BEGIN menu -->
      <!-- <div class="menu">
        <div class="menu-header">Navigation</div>
        <div class="menu-item active">
          <a href="index.html" class="menu-link">
            <span class="menu-icon"><i class="bi bi-house-door"></i></span>
            <span class="menu-text">Home</span>
          </a>
        </div>
      </div> -->

      <div class="menu">
        <Menu v-for="menu in menus" ref="menus" :title="menu.title" :items="menu.items" />
      </div>
      <!-- END menu -->

      <div class="p-3 px-4 mt-auto">
        <a href="https://cimon.com" class="btn d-block btn-outline-theme">
          <i class="fa fa-code-branch me-2 ms-n2 opacity-5"></i> CIMON
        </a>
      </div>
    </div>
    <!-- END scrollbar -->
  
  </div>
  <!-- END #sidebar -->
  <!-- </sidebar> -->
</template>

<script>
import Menu from "./Sidebar/Menu.vue"

export default {
  components: {
    Menu,
  },

  name: 'DashboardSidebar',
  props: {
    msg: String,
    layout: String,
  },

  data() {
    return {
      menus: [],
      selectedItem: null,
    }
  },

  methods: {
    render(menus) {
      this.menus = menus;
    },

    renderJson(layoutJson) {
      if (!layoutJson)
        return;

      let layout = {}
      try {
        layout = JSON.parse(layoutJson);
      } catch (err) {
        console.log("JSON parsing error: " + err);
        throw err;
        return;
      }

      if (layout) {
        // if ("menus" in layout)
        //   this.menus = layout["menus"];
        this.menus = layout;
      }
    },

    autoSelect() {
      this.clearSelection();
      if (this.menus.length > 0 && this.menus[0].items.length > 0) {
        if (this.menus[0].items[0].items.length == 0) {
          this.$refs.menus[0].$refs.menuItems[0].select();
        }
      }
    },

    clearSelection() {
      if (this.selectedItem) 
        this.selectedItem.clearSelection();
      this.selectedItem = null;
      // this.$parent.$refs.content.render();
    },

    handleSelection(item) {
      // Clear selection
      this.clearSelection();
      this.selectedItem = item;
      this.renderContent(item);
    },

    renderContent(item) {
      if (item.url)
        return;
      
      this.$parent.$refs.content.render(item.path, item.name, item.description, item.content.type, item.content.params);
    },
  },

  created() {
    // Parse sidebar layout
    this.renderJson(this.layout);
  },

  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
