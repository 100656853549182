/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c13b3a93-ea97-472a-9b4e-11bf0c23ce3c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Eyubes24j",
    "aws_user_pools_web_client_id": "7g65pt4skj2ocf1do5do7i9kmh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cimoncloud-storage90215-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
