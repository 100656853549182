<template>
  <div ref="toast" class="toast fade mb-3 hide" data-autohide="false">
    <div class="toast-header">
      <i v-bind="icon"></i>
      <strong class="me-auto">{{title}}</strong>
      <small>{{timestampStr}}</small>
      <!-- <button @click="close" type="button" class="btn-close" data-bs-dismiss="toast"></button> -->
      <button @click="close" type="button" class="btn-close"></button>
    </div>
    <div class="toast-body">
      {{body}}
    </div>
  </div>
</template>

<script>

export default {

  name: 'Toast',
  props: {
    id: {
      type: Number,
      required: true,
    },
    icon: {
      type: Object,
      default: {
        class: "far fa-bell text-muted me-2"
      },
    },
    title: {
      type: String,
      default: "Notification"
    },
    timestamp: {
      type: Number,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      timestampStr: "",
    }
  },

  methods: {
    // getIndex() {
    //   return 0;
    // },

    formatTimestamp() {
      let seconds = Math.floor((Date.now() - this.timestamp) / 1000);
      if (seconds == 0)
        return "Just now";
      // console.log(seconds)
      let minutes = Math.floor(seconds / 60);
      if (minutes == 0) {
        // return "Just now";
        return seconds + " seconds ago";
      }
      return minutes + " mins ago";
    },

    refreshTimestampStr() {
      this.timestampStr = this.formatTimestamp();
    },

    close() {
      this.$parent.remove(this.id);
      // console.log("Notification " + this.id + " has been removed")
    }
  },

  created() {
  },

  mounted() {
  },
}
</script>
