import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);
/*
Amplify.configure({
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_JdP7APmsK",
    userPoolWebClientId: "5bk70n7d9mvuqm73lk6bqrreko",
    identityPoolId: "us-west-2:9f3bdbfc-fd98-40dc-864a-4cd6aefd5f78",
    // oauth: {
    //   domain: "<enter here the amazon cognito domain>",
    //   scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    //   redirectSignIn: "<enter here the amplify hosted url>",
    //   redirectSignOut: "<enter here the amplify hosted url>",
    //   responseType: "code"
    // }
  },
  // API: {
  //   endpoints: [
  //     {
  //       name: "MyBlogPostAPI",
  //       endpoint: "<enter here the API gateway endpoint url>"
  //     }
  //   ]
  // }

  Storage: {
    AWSS3: {
        bucket: 'cimoncloud-storage91839-dev', //REQUIRED -  Amazon S3 bucket name
        region: 'us-west-2', //OPTIONAL -  Amazon service region
    }
  }
});
//*/
import {Hub} from "aws-amplify";
  
const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in');
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            console.log('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            console.log('token refresh failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
    }
}

Hub.listen('auth', listener);

// let app = createApp(App);

// app.config.globalProperties.$debug = false;

// app.use(router).mount('#app');
createApp(App).use(router).mount('#app');