<template>
	<!-- <div class="menu-item active"> -->
	<div ref="menuItem" class="menu-item"><!-- :class="{ 'has-sub': item.length }"> -->
		<!-- <a href="index.html" class="menu-link">
	      <span class="menu-icon"><i class="bi bi-house-door"></i></span>
	      <span class="menu-text">Home</span>
	    </a> -->

		<template v-if="items.length == 0">
			<a :href="url" class="menu-link" @click="select">
				<span class="menu-icon" v-if="icon">
					<i v-bind="icon"></i>
					<span v-if="hasNewNotification" class="w-5px h-5px rounded-3 bg-theme position-absolute top-0 end-0 mt-3px me-3px"></span>
				</span>
				<span class="menu-text">{{name}}</span>
			</a>
		</template>
		<template v-else>
			<a class="menu-link" @click="toggleSubmenu">
				<span class="menu-icon" v-if="icon">
					<i v-bind="icon"></i>
					<span v-if="hasNewNotification" class="w-5px h-5px rounded-3 bg-theme position-absolute top-0 end-0 mt-3px me-3px"></span>
				</span>
				<span class="menu-text">{{name}}</span>
				<span class="menu-caret"><b class="caret"></b></span>
			</a>
			<div class="menu-submenu">
				<MenuItem v-for="item in items" v-bind="item" ref="menuItems" />
			</div>
		</template>
	</div>
</template>

<script>
export default {

	name: "MenuItem",
	props: {
		icon: Object,
		iconDecor: String,
		name: String,
		items: {
			type: Object,
			// required: true
			default: [],
		},
		url: String,
		content: {
			type: Object,
			default: {
				// title: this.name,
				// description: "",
				type: "CardContainer",
				params: {
					cards: [],
					editable: false
				},
				editable: true,
			},
		},
	},
	watch: {
		name: function(newVal, oldVal) {
			this.path = this.getRoot().pathItems;
		}
	},

	data() {
		return {
			isSelected: false,
			path: [],
			hasNewNotification: false,
		}
	},

	methods: {
		getRoot() {
			let node = this;
			let pathItems = [];
			while (node.$options.name != "Menu") {
				pathItems.unshift(node.name);
				node = node.$parent;
			}
			let menuRoot = node;
			return { menuRoot, pathItems };
		},

		toggleSubmenu() {
			// class="menu-item has-sub expand"
			// console.log(this.$refs.menuItem)
			this.$refs.menuItem.classList.toggle("expand");

			// class="menu-submenu" style="display: block/none;"
		},

		select() {
			if (this.isSelected)
				return;

			this.isSelected = true;
			this.$refs.menuItem.classList.add("active");

			this.getRoot().menuRoot.handleSelection(this);
		},

		clearSelection() {
			this.isSelected = false;
			this.$refs.menuItem.classList.remove("active");
		},

		notify() {
			this.hasNewNotification = true;
		},

		clearNotification() {
			this.hasNewNotification = false;
		},
	},

	created() {
		this.path = this.getRoot().pathItems;
	},
}

</script>

<style scoped>
a {
	cursor: pointer;
}
</style>