<template>
  <!-- BEGIN login -->
  <div class="login">
    <!-- BEGIN login-content -->
    <div class="login-content">
      <!-- <form action="index.html" method="POST" name="login_form"> -->
        <h1 class="text-center">Confirm Sign Up</h1>
        <div class="text-white text-opacity-50 text-center mb-4">
          For your protection, please verify your identity.
        </div>
        <div class="mb-3">
          <label class="form-label">Email Address <span class="text-danger">*</span></label>
          <input ref="email" type="text" class="form-control form-control-lg bg-white bg-opacity-5" value="" placeholder="" />
          <div ref="emailFeedback" class="invalid-feedback">{{emailFeedbackMessage}}</div>
        </div>
        <div class="mb-3">
          <div class="d-flex">
            <label class="form-label">Code <span class="text-danger">*</span></label>
            <!-- <a href="#" class="ms-auto text-white text-decoration-none text-opacity-50">Forgot password?</a> -->
          </div>
          <input ref="code" type="password" class="form-control form-control-lg bg-white bg-opacity-5" value="" placeholder="" />
          <div ref="codeFeedback" class="invalid-feedback">{{codeFeedbackMessage}}</div>
        </div>
        <!-- <div class="mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="customCheck1" />
            <label class="form-check-label" for="customCheck1">Remember me</label>
          </div>
        </div> -->
        <div>
          <button ref="resendButton" type="button" class="btn btn-outline-default btn-sm" @click="resendConfirmationCode" disabled>Resend confirmation code</button>
          <label class="ms-auto text-white text-decoration-none text-opacity-50 btn-sm">{{formatTimeSec}}</label>
          <br /><br />
        </div>
        <!-- <button type="submit" class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Sign In</button> -->
        <button class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" @click="confirmSignUp">Confirm</button>
        <div class="text-center text-white text-opacity-50">
          Don't have an account yet? <a href="#" @click="this.$parent.openPage('RegisterPage');">Sign up</a>.
        </div>
      <!-- </form> -->
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Auth } from 'aws-amplify';



export default {
  name: 'RegisterConfirmationPage',
  // components: {
  //   HelloWorld
  // }
  props: {
    username: String,
  },

  data() {
    return {
      emailFeedbackMessage: "",
      codeFeedbackMessage: "",
      timeSec: 60
    }
  },

  methods: {
    validateInputs(email, code="default") {
      // Reset
      this.$refs.email.classList.remove("is-invalid");
      this.$refs.code.classList.remove("is-invalid");

      let result = true;
      if (!this.$parent.validateEmail(email)) {
        console.log("Invalid email");
        this.emailFeedbackMessage = "Please enter a valid email.";
        this.$refs.email.classList.add("is-invalid");
        result = false;
      }
      if (this.$parent.isEmpty(code)) {
        console.log("Code is empty.");
        this.codeFeedbackMessage = "Please enter a code.";
        this.$refs.code.classList.add("is-invalid");
        result = false;
      }

      if (!result)
        this.clearPassword();
      return result;
    },

    // Confirm sign up
    async confirmSignUp() {
      let username = this.$refs.email.value;
      let code = this.$refs.code.value;
      // Validate inputs
      if (!this.validateInputs(username, code))
        return;

      try {
        await Auth.confirmSignUp(username, code);

        // this.$router.push('login');
        this.$parent.openPage("LoginPage", username);
      } catch (error) {
        console.log('error confirming sign up', error);
        this.handleError(error);
      }
    },

    // Re-send confirmation code
    async resendConfirmationCode() {
      let username = this.$refs.email.value;
      // Validate inputs
      if (!this.validateInputs(username))
        return;

      try {
        await Auth.resendSignUp(username);
        console.log('code resent successfully');

        this.$refs.resendButton.disabled = true;
        this.timeSec = 60;
        this.countDown();
      } catch (err) {
        console.log('error resending code: ', err);
        this.handleError(err);
      }
    },

    handleError(error) {
      this.clearPassword();
      switch (error.name) {
        case "UserNotFoundException":
          console.log(error.message);
          this.emailFeedbackMessage = error.message;
          this.$refs.email.classList.add("is-invalid");
          break;
        case "CodeMismatchException":
          console.log(error.message);
          this.codeFeedbackMessage = error.message;
          this.$refs.code.classList.add("is-invalid");
          break;
        case "LimitExceededException":
          console.log(error.message);
          this.codeFeedbackMessage = error.message;
          this.$refs.code.classList.add("is-invalid");
          break;
      }
    },

    parseEmail() {
      // let searchParams = new URLSearchParams(window.location.search);
      // if (searchParams.has("user")) {
      //   this.$refs.email.value = searchParams.get("user");
      // }

      // if (this.$route.params.username) {
      //   this.$refs.email.value = this.$route.params.username;
      // }

      if (this.username) {
        this.$refs.email.value = this.username;
      }
    },

    countDown() {
      if (this.timeSec > 0) {
        setTimeout(() => {
          this.timeSec--;          
          this.countDown();
        }, 1000);
      }
    },
    formatSec(seconds) {
      if (seconds <= 0) {
        this.$refs.resendButton.disabled = false;
        return "";
      }
      let min = (Math.floor(seconds / 60)).toString();
      let sec = (seconds % 60).toString();
      if (sec.length < 2) {
        sec = "0" + sec;
      }
      return min + ":" + sec;
    },

    clearPassword() {
      this.$refs.code.value = "";
    },
  },
  computed: {
    formatTimeSec() {
      if (this.timeSec == 0) {
        this.$refs.resendButton.disabled = false;
        return "";
      }
      let min = (Math.floor(this.timeSec / 60)).toString();
      let sec = (this.timeSec % 60).toString();
      if (sec.length < 2) {
        sec = "0" + sec;
      }
      return min + ":" + sec;
    }
  },

  mounted() {
    this.parseEmail();
    this.countDown();
  }
}

</script>
