<template>
  <!-- <div id="container" class="app"> -->
  <div ref="container" class="app app-footer-fixed">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- app-header -->
    <Header title="CIMON CLOUD" />
    
    <!-- app-sidebar -->
    <Sidebar ref="sidebar" :layout="sidebarLayout" />

    <!-- BEGIN mobile-sidebar-backdrop -->
    <!-- <button class="app-sidebar-mobile-backdrop" data-toggle-target=".app" data-toggle-class="app-sidebar-mobile-toggled"></button> -->
    <button class="app-sidebar-mobile-backdrop" @click="toggleSidebarMobile" />
    <!-- END mobile-sidebar-backdrop -->
    
    <!-- app-content -->
    <Content ref="content" />
    <!-- <div id="footer" class="app-footer">
      Copyright © 2022 CIMON Co., Ltd. All rights reserved.
    </div> -->

    <ToastsContainer ref="toasts" :size="10" />
    
    <!-- btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>

    <ThemePanel v-show="showThemePanel" ref="themePanel"></ThemePanel>

    <Modal ref="modal" v-bind="modalParams"></Modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Dashboard/Header.vue"
import Sidebar from "@/components/Dashboard/Sidebar.vue"
import Content from "@/components/Dashboard/Content.vue"
import ToastsContainer from "@/components/Dashboard/ToastsContainer.vue"
import ThemePanel from "@/components/ThemePanel.vue"
import Modal from "@/components/Dashboard/Modal/Modal.vue"
// import Config from "/public/data/a.json"
// import DefaultConfig from "@/data/default.json";
// import TestConfig from "@/data/test.json";
function importData(file) {
  return import("/public/data/" + file + ".json").then(module => module.default);
}

import { Auth, Storage } from 'aws-amplify';

export default {
  components: {
    Header,
    Sidebar,
    Content,
    ToastsContainer,
    ThemePanel,
    Modal
  },

  name: 'DashboardView',
  props: {
    data: {
      type: String,
      default: ""
    },
  },

  data() {
    var self = this;
    return {
      showThemePanel: false,
      sidebarLayout: "",
      // contentLayout: "",//"block",
      config: "default",
      // isOnline: navigator.onLine,
      modalParams: {
        // size: "xl",
        title: "Test",
        // type: "SimpleModal",
        type: "SimpleModal",
        params: {
          body: "hello, world"
        },
      },
      debug: function(e) {
        // console.log(e)
        // if (e.key == '+') {
        //   // console.log("+ pressed")
        //   self.toggleModal();
        // }

        switch (e.key) {
          // case "+":
          //   self.modalParams = {
          //     title: "Add Card",
          //     type: "CardCreator",
          //   };
          //   break;
          case "*":
            self.modalParams = {
              title: "Edit Dashboard",
              type: "ContentEditor",
            };
            break;
          case "^":
            self.modalParams = {
              title: "Edit Menu",
              type: "SidebarEditor",
            };
            break;
          default:
            return;
        }
        // self.toggleModal();
        self.showModal();
      },
      authenticated: false,
    }
  },

  methods: {
    toggleSidebarMobile() {
      this.$refs.container.classList.toggle("app-sidebar-mobile-toggled");
    },

    pushNotification(title, body, icon, autoExpire=5000) {
      this.$refs.toasts.pushNotification(title, body, icon, autoExpire);
    },

    // toggleModal() {
    //   if (this.$refs.modal.hidden) {
    //     this.$refs.modal.show();
    //   } else {
    //     this.$refs.modal.hide();
    //   }
    // },
    showModal(resolve=result => result, reject=error => error, size) {
      if (this.$refs.modal.hidden) {
        if (this.$refs.modal.sizes.includes(size)) {
          this.$refs.modal.show(resolve, reject, size);
        } else {
          this.$refs.modal.show(resolve, reject);
        }
      }
    },
    hideModal() {
      if (!this.$refs.modal.hidden)
        this.$refs.modal.hide();
    },

    enableDebugMode() {
      this.showThemePanel = true;
      // this.pushNotification("Debug Mode", "Debug mode is on", {class: "fas fa-wrench text-muted me-2"}, 0);
      this.pushNotification("Debug Mode", "Debug mode is on", {class: "fas fa-wrench text-muted me-2"});

      window.addEventListener('keydown', this.debug);
    },

    disableDebugMode() {
      this.showThemePanel = false;
      // this.pushNotification("Debug Mode", "Debug mode is off", {class: "fas fa-wrench text-muted me-2"}, 0);
      this.pushNotification("Debug Mode", "Debug mode is off", {class: "fas fa-wrench text-muted me-2"});

      window.removeEventListener('keydown', this.debug);
    },

    handleOnlineStatusChange(isOnline) {
      if (isOnline) {
        this.pushNotification("Internet Connection", "Online", {class: "bi bi-globe text-muted me-2"}, 0);
      } else {
        this.pushNotification("Internet Connection", "Offline", {class: "bi bi-globe text-muted me-2"}, 0);
      }
    },

    async getData(name) {
      let jsonStr = "";
      try {
        let obj = await importData(name);
        // console.log(obj)
        jsonStr = JSON.stringify(obj, null, '\t');
      } catch (error) {
        // console.log(error)
        if (name !== "default" && this.isAuthenticated())
          jsonStr = await this.getConfig();
      }
      return jsonStr;
    },

    async getConfig(name="default") {
      let jsonStr = "";
      try {
        let result = await Storage.get(".config/" + name + ".config", { 
          level: "private", 
          download: true 
        });

        // data.Body is a Blob
        /*
        result.Body.text().then(string => { 
          // handle the String data return String 
          // console.log(string)
          this.$refs.sidebar.renderJson(string);
        })
        .then(value => {
          this.$refs.sidebar.autoSelect();
        })
        //*/

        // Block rendering
        jsonStr = await result.Body.text();
      } catch (err) {
        console.log(err)
        // if (err.toString().startsWith("NoSuchKey"))
        //   console.log("err")
        jsonStr = await this.getData(name);
      }
      return jsonStr;
    },

    async createConfig() {

    },

    async deleteConfig(name) {
      this.modalParams = {
        title: "Confirm Delete",
        type: "Confirmation",
        params: {
          body: "Are you sure to delete " + name + "?",
          confirmButton: "Yes",
          denyButton: "No",
          confirmPhrase: "delete"
        },
      };
      let modalClosed = new Promise((resolve, reject) => {
        this.showModal(resolve, reject);
      });
      
      let result = await modalClosed;
      if (!result)
        return;

      console.log("Deleting dashboard " + name + "...");
    },

    async handleQuery() {
      let config = this.$route.query.name;
      let func = this.$route.query.func;
      switch (func) {
        case "create":
          console.log("Creating dashboard " + this.config + "...")
          break;
        case "delete":
          // console.log("Deleting dashboard " + this.config + "...")
          this.deleteConfig(config);
          break;
        case undefined:
        case "read":
          break;
        default:
          console.log("Error: Unknown function " + func);
          // return;
      }
    },

    async isAuthenticated() {
      try {
        await Auth.currentAuthenticatedUser();
        return true;
      } catch {
        return false;
      }
    }
  },

  created() {
  },

  async mounted() {
    // console.log(document.documentElement)
    document.documentElement.classList.add('bg-cover-4')
    // this.$refs.themePanel.show();
    // this.$refs.themePanel.changeCover("bg-cover-2");
    // this.$refs.themePanel.changeTheme("theme-pink");

    window.addEventListener('keyup', (e) => {
      if (e.key === 'F2') {
        // console.log("F2 pressed");
        if (!this.showThemePanel) {
          this.enableDebugMode();
        } else {
          this.disableDebugMode();
        }
      } else if (e.key === "Escape") {
        this.hideModal();
      }
    });

    window.addEventListener('online', () => { this.handleOnlineStatusChange(true) });
    window.addEventListener('offline', () => { this.handleOnlineStatusChange(false) });


    this.authenticated = await this.isAuthenticated();
    let config = this.$route.query.name;
    if (config)
      this.config = config;

    let string = "";
    if (this.authenticated) {
      string = await this.getConfig(this.config);
    } else {
      if (this.data) {
        string = this.data;
      } else {
        let data = this.$route.query.data;
        if (!data) {
          string = await this.getData(this.config);
        } else {
          string = atob(data);
        }
      }
    }
    // console.log(string)

    this.$refs.sidebar.renderJson(string);
    await this.$nextTick();
    this.$refs.sidebar.autoSelect();

    if (this.authenticated)
      this.handleQuery();
  },

  unmounted() {
    document.documentElement.classList.remove('bg-cover-4')
    // this.$refs.themePanel.changeCover("bg-cover-1");
  },
}
</script>
