<template>
  <!-- BEGIN modal -->
  <div ref="modal" class="modal fade">
    <div ref="dialog" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="hide"></button>
        </div>

        <!-- <div class="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-bs-dismiss="modal" @click="hide">Close</button>
          <button type="button" class="btn btn-outline-theme">Save changes</button>
        </div> -->
        <component ref="child" :is="resolveType" :hidden=hidden :size=childSize :params=params />

      </div>
    </div>
  </div>
  <div ref="backdrop" v-show="!hidden" class="modal-backdrop fade" @click="hide"></div>
  <!-- END modal -->
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent, markRaw } from 'vue'

const SimpleModal = defineAsyncComponent(() =>
  import("./SimpleModal.vue")
)
const TestModal = defineAsyncComponent(() =>
  import("./TestModal.vue")
)
const CardCreator = defineAsyncComponent(() =>
  import("./CardCreator.vue")
)
const CardEditor = defineAsyncComponent(() =>
  import("./CardEditor.vue")
)
const ContentEditor = defineAsyncComponent(() =>
  import("./ContentEditor.vue")
)
const SidebarEditor = defineAsyncComponent(() =>
  import("./SidebarEditor.vue")
)

const Confirmation = defineAsyncComponent(() =>
  import("./Confirmation.vue")
)

export default {

  name: 'Modal',
  props: {
    // size: String,
    title: String,
    type: {
      type: String,
      reqired: true,
    },
    params: Object,
  },

  data() {
    return {
      SimpleModal: markRaw(SimpleModal),
      TestModal: markRaw(TestModal),
      CardCreator: markRaw(CardCreator),
      CardEditor: markRaw(CardEditor),
      ContentEditor: markRaw(ContentEditor),
      SidebarEditor: markRaw(SidebarEditor),
      Confirmation: markRaw(Confirmation),

      sizes: ["xl", "lg", "sm", ""],
      // currentSize: this.size,
      size: "",
      childSize: "",
      hidden: true,
      resolutionFunc: function(result) {return result},
      // rejectionFunc: (error) => {return error},
      rejectionFunc: error => error
    }
  },
  computed: {
    resolveType() {
      if (this.type in this)
        return this[this.type];
      else {
        console.log("Error: " + this.type + " modal type is not supported.");
      }
    }
  },
  watch: {
    size(newSize, oldSize) {
      // console.log(newSize, oldSize)
      if (!this.sizes.includes(newSize))
        return;

      if (newSize == oldSize)
        return;

      if (oldSize)
        this.$refs.dialog.classList.remove("modal-" + oldSize);
      if (newSize)
        this.$refs.dialog.classList.add("modal-" + newSize);
    }
  },

  methods: {
    show(resolve, reject, size) {
      this.resolutionFunc = resolve;
      this.rejectionFunc = reject;
      // this.setSize(size);
      this.childSize = size;

      this.hidden = false;
      this.$refs.modal.classList.add("show");
      this.$refs.modal.style = "display: block;"

      this.$refs.modal.removeAttribute("aria-hidden");
      this.$refs.modal.setAttribute("aria-modal", "true");
      this.$refs.modal.setAttribute("role", "dialog");

      document.body.classList.add("modal-open");
      document.body.style = "overflow: hidden; padding-right: 17px;";

      this.$refs.backdrop.classList.add("show");
    },

    hide() {
      // this.setSize(size);

      this.hidden = true;
      this.$refs.modal.classList.remove("show");
      this.$refs.modal.style = "display: none;"

      this.$refs.modal.removeAttribute("aria-modal");
      this.$refs.modal.setAttribute("aria-hidden", "true");
      this.$refs.modal.removeAttribute("role");

      document.body.classList.remove("modal-open");
      document.body.style = "";

      this.$refs.backdrop.classList.remove("show");
    },
  },

  created() {
    
  },

  mounted() {
    // this.setSize(this.size);
  },
}
</script>
