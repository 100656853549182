<template>
  <!-- BEGIN toasts-container -->
  <div class="toasts-container">

    <!-- <div ref="toast1" class="toast fade mb-3 hide" data-autohide="false">
      <div class="toast-header">
        <i class="far fa-bell text-muted me-2"></i>
        <strong class="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>

    <div ref="toast2" class="toast fade mb-3 hide" data-autohide="false">
      <div class="toast-header">
        <i class="far fa-bell text-muted me-2"></i>
        <strong class="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div> -->

    <Toast v-for="toast in toasts" v-bind="toast" ref="toasts" />

  </div>
  <!-- END toasts-container -->
</template>

<script>
import Toast from './Notification/Toast.vue'


// give each notification a unique id
let id = 0;

export default {
  components: {
    Toast,
  },

  name: 'ToastContainer',
  props: {
    size: {
      type: Number,
      default: 5,
    }
  },

  data() {
    return {
      notifications: {
        queue: [],  // notification queue
        size: 10,
      },
      toasts: [],
    }
  },

  methods: {
    // Push a notification into the queue
    push(title, body, icon=null) {
      var notification = {};
      notification.id = id++;
      if (title)
        notification.title = title;
      notification.body = body;
      if (icon)
        notification.icon = icon;
      notification.timestamp = Date.now();
      this.notifications.queue.push(notification);

      if (this.notifications.queue.length > this.notifications.size) {
        delete this.notifications.queue[0];
        this.notifications.queue.shift();
      }

      return notification.id;
    },

    // Remove a notification from the queue
    remove(id) {
      for (let i = 0; i < this.notifications.queue.length; i++) {
        if (this.notifications.queue[i].id === id) {
          delete this.notifications.queue[i];
          this.notifications.queue.splice(i, 1);
        }
      }
      this.update();
    },

    // Update toasts
    update() {
      if (this.size >= this.notifications.queue.length) {
        this.toasts = this.notifications.queue;
      } else {
        this.toasts = this.notifications.queue.slice(-this.size);
      }
    },

    async show() {
      await this.update();
      for (let i = 0; i < this.toasts.length; i++) {
        this.$refs.toasts[i].refreshTimestampStr();
      }
      $('.toast').toast('show');
    },

    pushNotification(title, body, icon=null, autoExpire=5000) {
      let id = this.push(title, body, icon);
      if (autoExpire) {
        setTimeout(() => {
          this.remove(id);
        }, autoExpire)
      }
      this.show();
    }
  },

  created() {
    
  },

  mounted() {
  },
}
</script>
