<template>
  <!-- BEGIN theme-panel -->
  <!-- <div ref="themePanel" class="app-theme-panel" hidden> -->
  <div ref="themePanel" class="app-theme-panel">
    <div class="app-theme-panel-container">
      <a href="javascript:;" data-toggle="theme-panel-expand" class="app-theme-toggle-btn"><i class="bi bi-sliders"></i></a>
      <!-- <a href="javascript:;" class="app-theme-toggle-btn" @click="toggleThemePanel"><i class="bi bi-sliders"></i></a> -->
      <div class="app-theme-panel-content">
        <div class="small fw-bold text-white mb-1">Theme Color</div>
        <div class="card mb-3">
          <!-- BEGIN card-body -->
          <div class="card-body p-2">
            <!-- BEGIN theme-list -->
            <div class="app-theme-list">
              <div class="app-theme-list-item"><a ref="theme-pink" href="javascript:;" class="app-theme-list-link bg-pink" data-theme-class="theme-pink" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Pink">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-red" href="javascript:;" class="app-theme-list-link bg-red" data-theme-class="theme-red" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Red">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-orange" href="javascript:;" class="app-theme-list-link bg-warning" data-theme-class="theme-warning" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Orange">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-yellow" href="javascript:;" class="app-theme-list-link bg-yellow" data-theme-class="theme-yellow" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Yellow">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-lime" href="javascript:;" class="app-theme-list-link bg-lime" data-theme-class="theme-lime" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Lime">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-green" href="javascript:;" class="app-theme-list-link bg-green" data-theme-class="theme-green" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Green">&nbsp;</a></div>
              <div class="app-theme-list-item active"><a ref="theme-teal" href="javascript:;" class="app-theme-list-link bg-teal" data-theme-class="" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-cyan" href="javascript:;" class="app-theme-list-link bg-info" data-theme-class="theme-info"  data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cyan">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-blue" href="javascript:;" class="app-theme-list-link bg-primary" data-theme-class="theme-primary"  data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Blue">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-purple" href="javascript:;" class="app-theme-list-link bg-purple" data-theme-class="theme-purple" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Purple">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-indigo" href="javascript:;" class="app-theme-list-link bg-indigo" data-theme-class="theme-indigo" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Indigo">&nbsp;</a></div>
              <div class="app-theme-list-item"><a ref="theme-black" href="javascript:;" class="app-theme-list-link bg-gray-100" data-theme-class="theme-gray-200" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Gray">&nbsp;</a></div>
            </div>
            <!-- END theme-list -->
          </div>
          <!-- END card-body -->
          
          <!-- BEGIN card-arrow -->
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
          <!-- END card-arrow -->
        </div>
        
        <div class="small fw-bold text-white mb-1">Theme Cover</div>
        <div class="card">
          <!-- BEGIN card-body -->
          <div class="card-body p-2">
            <!-- BEGIN theme-cover -->
            <div class="app-theme-cover">
              <div class="app-theme-cover-item active">
                <a ref="bg-cover-1" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-1.jpg);" data-theme-cover-class="" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-2" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-2.jpg);" data-theme-cover-class="bg-cover-2" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 2">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-3" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-3.jpg);" data-theme-cover-class="bg-cover-3" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 3">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-4" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-4.jpg);" data-theme-cover-class="bg-cover-4" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 4">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-5" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-5.jpg);" data-theme-cover-class="bg-cover-5" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 5">&nbsp;</a>
              </div>

              <div class="app-theme-cover-item">
                <a ref="bg-cover-6" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-6.jpg);" data-theme-cover-class="bg-cover-6" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 6">&nbsp;</a>
              </div>
              <!-- <div class="app-theme-cover-item">
                <a ref="bg-cover-7" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-7.jpg);" data-theme-cover-class="bg-cover-7" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 7">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-8" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-8.jpg);" data-theme-cover-class="bg-cover-8" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 8">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-9" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-9.jpg);" data-theme-cover-class="bg-cover-9" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 9">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-10" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-10.jpg);" data-theme-cover-class="bg-cover-10" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 10">&nbsp;</a>
              </div>
              <div class="app-theme-cover-item">
                <a ref="bg-cover-11" href="javascript:;" class="app-theme-cover-link" style="background-image: url(assets/img/cover/cover-thumb-11.jpg);" data-theme-cover-class="bg-cover-11" data-toggle="theme-cover-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cover 11">&nbsp;</a>
              </div> -->
            </div>
            <!-- END theme-cover -->
          </div>
          <!-- END card-body -->
          
          <!-- BEGIN card-arrow -->
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
          <!-- END card-arrow -->
        </div>
      </div>
    </div>
  </div>
  <!-- END theme-panel -->
</template>

<script>
export default {
  name: 'ThemePanel',
  props: {
    msg: String
  },

  methods: {
    changeTheme(name) {
      setTimeout(() => {
        // console.log(this.$refs[name]);
        this.$refs[name].click();
      }, "1");
    },

    changeCover(name) {
      setTimeout(() => {
        // console.log(this.$refs[name]);
        this.$refs[name].click();
      }, "1");
    },

    show() {
      this.$refs.themePanel.hidden = false;
    },
    hide() {
      this.$refs.themePanel.hidden = true;
    },

    toggleThemePanel() {
      this.$refs.themePanel.classList.toggle("active");
    },
  },

  mounted() {
    // console.log("hello");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
