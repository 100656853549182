<template>
  <!-- BEGIN #content -->
  <div id="content" class="app-content">
    <ul class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a href="#">BREADCRUMB</a></li>
      <li class="breadcrumb-item active">HOME PAGE</li> -->
      <li ref="listItems" class="breadcrumb-item" v-for="(item, index) in content.pathItems">
        <a v-if="index == 0" href="#">{{item}}</a>
        {{(index > 0) ? item : ""}}
      </li>
    </ul>
    
    <h1 class="page-header">
      <!-- Starter Page <small>page header description goes here...</small> -->
      {{content.title}} <small>{{content.description}}</small>
    </h1>
    
    <!-- <p>
      Start build your page here
    </p> -->

    <component ref="child" :is="resolveType" :params=content.params />
  </div>
  <!-- END #content -->
</template>

<script>
// import Card from '@/components/Dashboard/Card/Card.vue'
import { defineAsyncComponent, markRaw } from 'vue'

const CardContainer = defineAsyncComponent(() =>
  import("./Content/CardContainer.vue")
)
const Settings = defineAsyncComponent(() =>
  import("./Content/Settings.vue")
)

// let id = 0;
export default {
  // components: {
  //   Card,
  // },

  name: 'DashboardContent',
  props: {
    msg: String,
    layout: String,
  },

  data() {
    return {
      CardContainer: markRaw(CardContainer),
      Settings: markRaw(Settings),

      // pathItems: [],
      // title: "",
      // description: "",
      // type: "CardContainer",
      // params: {},
      content: {
        pathItems: [],
        title: "",
        description: "",
        type: "CardContainer",
        params: {
          cards: [],
          editable: false,
        },
      },
    }
  },
  computed: {
    resolveType() {
      if (this.content.type in this)
        return this[this.content.type];
      else {
        console.log("Error: " + this.content.type + " content type is not supported.");
      }
    }
  },

  methods: {
    render(pathItems=[], title="", description="", type="CardContainer", params={cards: [], editable: false}) {
      this.content.pathItems = pathItems;
      this.content.title = title;
      this.content.description = description;
      this.content.type = type;
      this.content.params = params;
    },

    renderJson(layoutJson) {
      if (!layoutJson)
        return;

      let layout = {}
      try {
        layout = JSON.parse(layoutJson);
      } catch (err) {
        console.log("JSON parsing error: " + err);
        throw err;
        return;
      }

      if (layout) {
        // if ("path" in layout)
        //   this.content.pathItems = layout["path"].split("/");
        if ("pathItems" in layout)
          this.content.pathItems = layout["pathItems"];
        if ("title" in layout)
          this.content.title = layout["title"];
        if ("description" in layout)
          this.content.description = layout["description"];
        if ("type" in layout)
          this.content.type = layout["type"];
        if ("params" in layout)
          this.content.params = layout["params"];
      }
    }
  },

  created() {
    // Parse content layout
    this.renderJson(this.layout);
  },

  mounted() {
    if (this.content.pathItems.length > 0)
      this.$refs.listItems[this.content.pathItems.length - 1].classList.add("active");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
