<template>
	<div class="menu-header">{{title}}</div>
  <MenuItem v-for="item in items" v-bind="item" ref="menuItems" />
</template>

<script>
import MenuItem from "./MenuItem.vue"

export default {
	components: {
		MenuItem,
	},

	name: "Menu",
	props: {
		title: String,
		items: {
			type: Object,
			default: []
		},
		editable: {
			type: Boolean,
			default: true
		},
	},

	data() {
		return {
			// selectedItem: null,
		}
	},

	methods: {
		toggleSubmenu(index) {
			// class="menu-item has-sub expand"
			// console.log(this.$refs.menuItems[index])
			this.$refs.menuItems[index].classList.toggle("expand");

			// class="menu-submenu" style="display: block/none;"
		},

		handleSelection(item) {
			this.$parent.handleSelection(item);
		},
	},
}

</script>

<style scoped></style>