import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView'
import RegisterView from '../views/RegisterView'
import DashboardView from '../views/DashboardView.vue'
import RegisterConfirmationView from '../views/RegisterConfirmationView'
import AuthView from '../views/AuthView'
import AuthViewStatic from '../views/AuthViewStatic'
import ErrorView from '../views/ErrorView'
import ComingSoonView from '../views/ComingSoonView'

import {Auth} from 'aws-amplify';

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    alias: ['/coming-soon'],
    name: 'ComingSoon', 
    component: ComingSoonView,
    // props: { showSubscribeDivs: true, date: new Date("2022-09-01T03:24:00") }
  },
  {
    // path: '/',
    // alias: ['/login'],
    path: '/login',
    name: 'LoginPage',
    component: AuthView
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginView
  // },
  {
    path: '/register',
    // name: 'register',
    // component: RegisterView
    name: 'RegisterPage',
    component: AuthView
  },
  {
    path: '/confirm',
    // name: 'confirm',
    // component: RegisterConfirmationView
    name: 'RegisterConfirmationPage',
    component: AuthView
  },
  {
    path: '/recover',
    name: 'PasswordRecoveryPage',
    component: AuthView
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthViewStatic
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    beforeEnter: async (to, from) => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log(error);
        return {name: 'LoginPage'};
      }
    },
  },
  {
    path: '/render',
    name: 'RenderDashboard',
    component: DashboardView,
    // props: {  }
  },
  // { 
  //   path: '/coming-soon', 
  //   name: 'ComingSoon', 
  //   component: ComingSoonView 
  // },
  { 
    path: '/:pathMatch(.*)', 
    name: 'NotFound', 
    component: ErrorView 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(async (to, from) => {
//   if (
//     // make sure the user is authenticated
//     // !isAuthenticated &&
//     !("user" in to.params) &&
//     // ❗️ Avoid an infinite redirect
//     to.name !== 'Login'
//   ) {
//     // redirect the user to the login page
//     return { name: 'Login' }
//   }
// })

export default router
